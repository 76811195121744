<template>
  <div class="Factory MERCH">
    <div class="merch-card">
      <!-- 搜索列 -->
      <div class="merch-search">
        <div class="search-box">
          <!-- 审核状态 -->
          <div class="sch-1 sch-2">
            <el-input
              placeholder="银行名称"
              v-model="UsersApi.bankName"
              maxlength="32"
            ></el-input>
          </div>

          <!-- 搜索 -->
          <div class="sch-1 sch-2 flex">
            <el-button type="primary" size="mini" @click="search">
              查询
            </el-button>
            <el-button type="primary" size="mini" @click="openAdd()">
              新建
            </el-button>
          </div>
        </div>
      </div>
      <!-- 列表 -->
      <div
        :style="`height: ` + mainHright + `px`"
        class="merch-table scorr-roll"
      >
        <div>
          <el-table
            :data="usersData.list"
            border
            style="width: 100%"
            :header-cell-style="{ background: '#F2F3F5' }"
          >
            <el-table-column label="序号" align="center" width="60">
              <template slot-scope="{ row }">
                <div class="exceed">{{ row.index }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="创建时间" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.createTime"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.createTime }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="银行编号" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.bankNo"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.bankNo }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="银行名称" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.bankName"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.bankName }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="银行图标"
              align="center"
              width="120"
            >
              <template slot-scope="{ row }">
                <div class="img-icon">
                  <img :src="row.bankPicture" alt="" />
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="使用用户"
              align="center"
              width="100"
            >
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.use + ''"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.use }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="状态"
              align="center"
              width="100"
            >
              <template slot-scope="{ row }">
                <span v-if="row.status == '0'">禁用</span>
                <span v-if="row.status == '1'">正常</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="操作"
              align="center"
              width="200"
            >
              <template slot-scope="{ row }">
                <div class="userBtn">
                  <!--  -->
                  <div @click="particulars(row)">详情</div>
                  <div @click="openAdd(row)">编辑</div>
                  <div @click="changeStatus(row, 1)" v-if="row.status == '0'">
                    启用
                  </div>
                  <div @click="changeStatus(row, 2)" v-if="row.status == '1'">
                    禁用
                  </div>
                  <div @click="changeStatus(row, 3)">删除</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 分页 -->
      <div class="merch-page">
        <pagination
          :total="usersData.total"
          :page.sync="UsersApi.currPage"
          :limit.sync="UsersApi.per_page"
          @pagination="toSearch"
        />
      </div>
      <!-- 新建编辑 -->
      <div>
        <el-dialog
          :title="bankAddType ? '新建银行' : '编辑银行信息'"
          :visible.sync="islogistics"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          width="500px"
        >
          <div class="bank-mess">
            <div class="bank-list">
              <span>*</span><span class="lable">银行编号</span>
              <el-input v-model="AddData.bankNo" :disabled="!bankAddType" />
            </div>
            <div class="bank-list">
              <span>*</span><span class="lable">银行名称</span>
              <el-input v-model="AddData.bankName" />
            </div>
            <div class="bank-list">
              <span>*</span><span class="lable">银行图标</span>
              <div>
                <!-- {{ Actions }} -->
                <!-- <el-upload
                  class="avatar-uploader"
                  :action='Actions'
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload> -->
                <div class="cover-box">
                  <el-upload
                    :action="Actions"
                    list-type="picture-card"
                    :on-remove="handleRemove"
                    :limit="1"
                    class="{disabled: uploadDisabled}"
                    :file-list="fileList"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <!-- <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="imageUrl" alt="" />
                  </el-dialog> -->
                  <div class="cover"></div>
                </div>

                <!-- <el-dialog :visible.sync="dialogVisibleImg">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog> -->
                <div class="upload-explain">
                  银行图标尺寸建议宽度50，高度50（图标比例不一致，可能导致图片挤压变形的情况）
                </div>
              </div>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="afiyAdd" v-if="bankAddType"
              >确认新建</el-button
            >
            <el-button type="primary" @click="afiyAdd" v-if="!bankAddType"
              >保 存</el-button
            >
            <el-button @click="islogistics = false">取 消</el-button>
          </span>
        </el-dialog>
      </div>
      <el-dialog
        :title="range.title"
        :visible.sync="dialogVisibleRange"
        width="500px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <div class="dialog-mess">
          {{ range.mess }}
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" v-if="range.type == 1" @click="affirm(1)">
            启用
          </el-button>
          <el-button type="primary" v-if="range.type == 2" @click="affirm(2)">
            禁用
          </el-button>
          <el-button type="primary" v-if="range.type == 3" @click="affirm(3)">
            删除
          </el-button>
          <el-button
            type="primary"
            @click="dialogVisibleRange = false"
            v-if="range.type == 4 || range.type == 5"
          >
            知道了
          </el-button>
          <el-button
            @click="dialogVisibleRange = false"
            v-if="range.type == 1 || range.type == 2 || range.type == 3"
          >
            取消
          </el-button>
          <!-- <el-button @click="dialogVisibleRange = false">知道了</el-button> -->
        </span>
      </el-dialog>
    </div>
    <el-dialog
      title="银行详情"
      :visible.sync="dialogVisibleDetails"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="500px"
    >
      <div class="bank-mess">
        <div class="bank-details">
          <span class="lable">银行编号</span>{{ details.bankNo }}
        </div>
        <div class="bank-details">
          <span class="lable">银行名称</span>{{ details.bankName }}
        </div>
        <div class="bank-details">
          <span class="lable">银行图标</span
          ><img :src="details.bankPicture" alt="" />
        </div>
        <div class="bank-details">
          <span class="lable">创建人</span>{{ details.creatorId }}
        </div>
        <div class="bank-details">
          <span class="lable">创建时间</span>{{ details.createTime }}
        </div>
        <div class="bank-details" v-if="details.operatorId">
          <span class="lable">最近编辑人</span>{{ details.operatorId }}
        </div>
        <div class="bank-details" v-if="details.operatorId">
          <span class="lable">最近编辑时间</span>{{ details.updateTime }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleDetails = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapState, mapActions } = createNamespacedHelpers("Basics"); //vuex公共库
import { javawholeUrl } from "@/api/javaUrl"; //引入接口
export default {
  data() {
    return {
      fileList: [{ url: "" }], //控制上传按钮,
      Actions: javawholeUrl + "/ptuser/common/upload",
      range: {
        title: "",
        type: null,
        name: "",
        id: "",
      },
      dialogVisibleRange: false,
      UsersApi: {
        bankName: "", // 单位名称 法人 手机号关键字筛选
        currPage: 1,
        per_page: 10,
      },
      usersData: {},
      AddData: {
        bankNo: "",
        bankName: "",
      },
      islogistics: false,
      bankAddType: null,
      imageUrl: "",
      dialogVisibleDetails: false,
      dialogVisible: false,
      details: {},
      userId: "",
    };
  },

  computed: {
    ...commonIndex.mapState([
      "AreaList",
      "userType",
      "mainHright",
      "allJurisdiction",
      "detailsSeach",
    ]),
  },
  created() {
    let router = this.$router.history.current.path.substr(1);
    if (this.detailsSeach[router]) {
      this.UsersApi = this.detailsSeach[router];
    }
    this.toSearch();
    this.userId = window.sessionStorage.getItem("admin_id");
    console.log(this.userId);
  },
  methods: {
    ...mapActions([
      "postbankSelectBank",
      "postbankAddBank",
      "postbankUpdateBank",
      "postbankDisabledBank",
      "getbankDeleteBank",
    ]),
    ...commonIndex.mapMutations(["changeSeach"]),
    handleRemove(file, fileList) {
      this.imageUrl = "";
      console.log(file, fileList);
    },
    particulars(val) {
      console.log(val);
      this.details = val;
      this.dialogVisibleDetails = true;
    },
    handleAvatarSuccess(res, file) {
      console.log(111, this.fileList);
      this.imageUrl = res.content.url;
    },
    beforeAvatarUpload(file) {
      console.log(file);
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isPNG && !isJPG) {
        this.$message.error(
          "请上传.png或,jpg格式的图片，且图片大小不超过1MB。"
        );
      }
      if (!isLt2M) {
        this.$message.error(
          "请上传.png或,jpg格式的图片，且图片大小不超过1MB。"
        );
      }
      return (isJPG || isPNG) && isLt2M;
    },
    // 确认操作
    async affirm(val) {
      if (val == 1) {
        let res = await this.postbankDisabledBank({
          id: this.range.id,
          status: 1,
        });
        if (res.code == "000000") {
          this.$message({
            message: `成功启用银行【${this.range.name}】`,
            type: "success",
          });
          this.dialogVisibleRange = false;
          this.search();
        } else {
          this.errormes(res.message);
        }
      } else if (val == 2) {
        let res = await this.postbankDisabledBank({
          id: this.range.id,
          status: 0,
        });
        if (res.code == "000000") {
          this.$message({
            message: `成功禁用银行【${this.range.name}】`,
            type: "success",
          });
          this.dialogVisibleRange = false;
          this.search();
        } else if (res.code == "607012") {
          this.range.type = 4;
          this.range.mess = `你无法禁用当前银行【${this.range.name}】，因为已有用户选用当前银行。`;
        } else {
          this.errormes(res.message);
        }
      } else if (val == 3) {
        let res = await this.getbankDeleteBank({ id: this.range.id });
        if (res.code == "000000") {
          this.$message({
            message: `成功删除银行【${this.range.name}】`,
            type: "success",
          });
          this.dialogVisibleRange = false;
          this.search();
        } else if (res.code == "607013") {
          this.range.type = 5;
          this.range.mess = `你无法删除当前银行【${this.range.name}】，因为已有用户选用当前银行。`;
        } else {
          this.errormes(res.message);
        }
        // this.range.type = 5;
        // this.range.mess = `你无法删除当前银行【${this.range.name}】，因为已有用户选用当前银行。`;
      }
    },
    // 启用，禁用，删除
    changeStatus(row, val) {
      if (val == 1) {
        this.range = {
          title: "启用银行提醒",
          type: val,
          name: row.bankName,
          id: row.id,
          mess: `确定要启用【${row.bankName}】，启用后设置银行相关信息时，将允许选择当前银行`,
        };
      }
      if (val == 2) {
        this.range = {
          title: "禁用银行提醒",
          type: val,
          name: row.bankName,
          id: row.id,
          mess: `确定要禁用【${row.bankName}】禁用后设置银行相关信息时，无法选择当前银行。`,
        };
      }
      if (val == 3) {
        this.range = {
          title: "删除银行提醒",
          type: val,
          name: row.bankName,
          id: row.id,
          mess: `确定要删除【${row.bankName}】，删除后设置银行相关信息时，无法选择当前银行。`,
        };
      }
      this.dialogVisibleRange = true;
    },
    errormes(val) {
      this.$message({
        message: val,
        type: "warning",
      });
      this.isBtn = false;
    },
    // 新增编辑确认
    async afiyAdd() {
      if (this.AddData.bankNo.length == 0) {
        this.errormes("银行编号不能为空。");
        return;
      }
      let regex = /^[a-zA-Z0-9]{6,16}$/; //
      if (!regex.test(this.AddData.bankNo)) {
        this.errormes("请输入6-16位由字母、数字组成的银行编号。");
        return;
      }
      if (this.AddData.bankName.length == 0) {
        this.errormes("银行名称不能为空");
        return;
      }
      let regex1 =
        /^[\u4e00-\u9fa5a-zA-Z0-9](?=.*[\u4e00-\u9fa5a-zA-Z]).{3,33}$/; //
      if (!regex1.test(this.AddData.bankName)) {
        this.errormes(
          "请输入4-32位由汉字、字母、数字组成的银行名称，且必须包含字母或汉字。"
        );
        return;
      }
      if (this.imageUrl.length == 0) {
        this.errormes("请先上传银行图标。");
        return;
      }
      let params = {
        bankPicture: this.imageUrl,
        bankNo: this.AddData.bankNo,
        bankName: this.AddData.bankName,
      };
      // params.bankPicture = this.imageUrl;
      let res = null;
      if (this.bankAddType) {
        // this.postbankAddBankApi(params)
        params.creatorId = this.userId;
        // 新增银行信息
        res = await this.postbankAddBank(params);
      } else {
        params.operatorId = this.userId;
        // 编辑银行信息
        params.id = this.AddData.id;
        res = await this.postbankUpdateBank(params);
      }
      if (res.code == "000000") {
        if (this.bankAddType) {
          this.$message({
            message: "新建银行成功",
            type: "success",
          });
        } else {
          this.$message({
            message: "银行信息编辑成功。",
            type: "success",
          });
        }
        this.toSearch();
        this.islogistics = false;
      } else {
        this.$message({
          message: res.message,
          type: "warning",
        });
      }
    },
    // 打开新建弹框
    openAdd(row) {
      if (!row) {
        this.AddData = {
          bankNo: "",
          bankName: "",
        };
        this.fileList = [];
        this.imageUrl = "";
        this.bankAddType = true;
        this.islogistics = true;
      } else {
        row = JSON.parse(JSON.stringify(row));
        this.AddData = row;
        this.fileList = [{ url: row.bankPicture }];
        this.imageUrl = row.bankPicture;
        // this.fileList[0].url=row.bankPicture;
        this.bankAddType = false;
        this.islogistics = true;
      }
    },
    handleCurrentChange(e) {
      this.UsersApi.currPage = e;
      this.toSearch();
    },
    search() {
      this.UsersApi.currPage = 1;
      this.toSearch();
    },
    async toSearch() {
      let data = await this.postbankSelectBank(this.UsersApi);
      let router = this.$router.history.current.path.substr(1);
      let save = { ...this.UsersApi };
      save.cascaderValue = this.cascaderValue;
      save.router = router;
      this.changeSeach(save);
      if (data.code == "000000") {
        data.content.list.forEach((v, i) => {
          let index =
            data.content.pageSize * (data.content.pageNum - 1) + i + 1;
          this.$set(v, "index", index);
          v.updateTime = this.$publicFunction.timestamp(v.updateTime);
        });
        this.usersData = data.content;
      }
    },
    // this.$router.push({
    //   path: "/usersMessage",
    //   query: { user_id: row.user_id, id: row.ID },
    // });
  },
};
</script>
<style lang="scss" scoped>
.dio-input {
  // .el-input {
  //   width: 300px !important;
  // }
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.el-tabs__item.is-active {
  color: #06b7ae;
}
.el-tabs__item:hover {
  color: #06b7ae;
}
.el-tabs__active-bar {
  background-color: #06b7ae;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}
</style>
<style lang="scss" scoped>
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    max-height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    // 搜索行样式
    .merch-search {
      margin-top: 1%;
      width: 100%;
      .search-box {
        display: flex;
        flex-wrap: wrap;
      }
      .sch-1 {
        margin-top: 10px;
        width: 18%;
        min-width: 200px;
        display: flex;

        .sch-title {
          width: 40%;
          min-width: 80px;
          height: 100%;
          font-size: 13px;
          background-color: #f5f7fa;
          color: #909399;
          border: 1px solid #dcdfe6;
          border-right: none;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 0px 0px 5px !important;
        }
      }
      .sch-2 {
        margin-right: 2%;
      }
    }
    .merch-table {
      margin-top: 1%;
      width: 100%;
      height: 77%;
    }
    .merch-page {
      margin-top: 10px;
      width: 100%;
      display: flex;
      align-content: center;
      justify-content: right;
    }
  }
}
.flex {
  flex: 1;
  justify-content: space-between;
}
.img-icon {
  img {
    width: 50px;
    height: 50px;
  }
}
::v-deep .el-dialog__body {
  padding: 0 20px;
}
.bank-mess {
  .bank-list {
    display: flex;
    // align-items: center;
    margin-top: 20px;
    span {
      color: #f00;
      line-height: 40px;
    }
    .lable {
      font-size: 15px;
      min-width: 90px;
      color: #333;
      margin-left: 5px;
    }
    .upload-explain {
      margin-top: 10px;
      line-height: 20px;
      margin-bottom: 10px;
    }
  }
  .bank-details {
    margin-top: 10px;
    display: flex;
    // height: 32px;
    line-height: 32px;
    color: #666;
    .lable {
      min-width: 95px;
      margin-right: 20px;
      color: #333;
      // text-align-last:  justify;
    }
    img {
      width: 50px;
      height: 50px;
    }
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
// .avatar-uploader-icon {
//   font-size: 28px;
//   color: #8c939d;
//   width: 100px;
//   height: 100px;
//   line-height: 100px;
//   text-align: center;
//   border: 1px dashed #ccc;
// }
// .avatar {
//   width: 100px;
//   height: 100px;
//   display: block;
// }
.dialog-mess {
  padding: 20px 0;
}
.cover-box {
  position: relative;
}
.cover {
  width: 80px;
  height: 80px;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 88px;
}
::v-deep .el-upload--picture-card {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
}
</style>
